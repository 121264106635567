import React, { useState,useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/login.css";
import {login} from "../../api";
import { Spin } from "antd";
import {checkJsonFile} from "../../api";
// import { makeRequest } from "../../api";
const Login_Details = ({ email, setEmail }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isuploadOpen, setIsUploadOpen] = useState(true)

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  const showPasswordHandler = (event) => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const credentialChangeHandler = (event) => {
    setError("");
    setMessage("");
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      if (email === "") {
        setError("email");
        setErrorMessage("Email Id Required");
      } else if (ValidateEmail(email) === false) {
        setError("email");
        setErrorMessage("Invalid Email Id");
      }
      setPassword(event.target.value);
    }
  };

  useEffect(() => {
    if (message?.includes("Enter a valid email address")) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    } else if (message?.includes("user")) {
      setErrorMessage("User not avilable");
      setError("email");
    } else if (message?.includes("Invalid")) {
      setError("password");
      setErrorMessage("Invalid Password");
    } else if (
      message?.includes(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      )
    ) {
      setErrorMessage(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      );
      setError("password");
    } else if (
      message?.includes(
        "Account temporary is blocked on this IP address. Please contact administrator"
      )
    ) {
      setDisabled(true);
    } else if (
      message?.includes("OTP is not valid") ||
      message?.includes("otp value is required")
    ) {
      setError("Otp");
    } else {
      setError("");
    }
  }, [message]);


  const checkJson = async () => {
    const email = JSON.parse(localStorage.getItem('email'));
    try {
            const checkJson_result = await checkJsonFile(email);
            console.log("checkJson_result : ",checkJson_result);
            setIsUploadOpen(checkJson_result);
    } catch (err) {
            console.error("json error :", err);
    }
}

  const loginRequest = async (loginData) => {
    setLoading(true);
    console.log(loginData);
        try {
          const response = await login(loginData);
          localStorage.setItem('email', JSON.stringify(response.email));
          const email = JSON.parse(localStorage.getItem('email'));
          if(email=="demo@vireinsights.com") localStorage.setItem('id', JSON.stringify("demo-Analysis"));
          else localStorage.setItem('id', JSON.stringify(response.id));
          const id = JSON.parse(localStorage.getItem('id'));
          const checkJson_result = await checkJsonFile(response.email);
          console.log("checkJson_result : ",checkJson_result);
          setIsUploadOpen(checkJson_result);
          // console.log("isuploadOpen : ",isuploadOpen);
          if(isuploadOpen){
            // navigate("/demo-analysis/upload");
            navigate(`/${id}/upload`);
          } 
          else{
            // navigate("/demo-analysis/dashboard");
            navigate(`/${id}/dashboard`);
          } 
          setError('');
      } catch (err) {
          setError('Invalid username or password');
          console.log("Invalid");
      }
  };


  const LoginHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (password === "") {
      setErrorMessage("The field is required");
      setError("password");
    } else {
      const loginData = {
        email: email,
        password: password,
      };
      loginRequest(loginData);
    }
  };

  const passwordFocusHandler = () => {
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (ValidateEmail(email) === false) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    }
  };

  // const encryptAES = (plaintext) => {
  //   const secretKey = "RUFqlY38xUWND5Yt";
  //   const key = CryptoJS.enc.Utf8.parse(secretKey);
  //   const iv = CryptoJS.lib.WordArray.random(16);
  //   const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
  //     iv: iv,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   const combined = CryptoJS.lib.WordArray.create();
  //   combined.concat(iv).concat(encrypted.ciphertext);
  //   return combined.toString(CryptoJS.enc.Base64);
  // };

  // const encryptPayload = (payload, secretKey) => {
  //   const encryptedPayload = {};
  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       encryptedPayload[key] = encryptAES(payload[key]);
  //     }
  //   }
  //   return encryptedPayload;
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (email === "") {
        setErrorMessage("The field is required");
        setError("email");
      } else if (password === "") {
        setErrorMessage("The field is required");
        setError("password");
      } else {
        const loginData = {
          email: email,
          password: password,
        };
        // loginRequest(encryptPayload(loginData));
      }
    }
  };

  return (
        <>
          <div className="Login_Title">
            <span>Login</span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "32px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "24px" }}
            >
              <div className="Login_Input">
                <label className="email_icon" htmlFor="login">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  onKeyDown={handleKeyDown}
                  placeholder="Enter your email"
                  className={error === "email" ? "input_warning" : ""}
                  value={email}
                  autoFocus="autofocus"
                  onChange={credentialChangeHandler}
                  id="login"
                />
                {error === "email" ? (
                  <label id="warning">{errorMessage}</label>
                ) : (
                  ""
                )}
              </div>
              <div className="Login_Input">
                <label className="password_icon" htmlFor="password">
                  Password
                </label>
                <div className="password">
                  <input
                    type={type}
                    name="password"
                    onKeyDown={handleKeyDown}
                    onFocus={passwordFocusHandler}
                    className={error === "password" ? "input_warning" : ""}
                    value={password}
                    onChange={credentialChangeHandler}
                    placeholder="Enter your password"
                    id="password"
                  />
                  {type === "text" ? (
                    <img
                      src={require("../../Assets/icon/eye.png")}
                      onClick={showPasswordHandler}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={showPasswordHandler}
                      src={require("../../Assets/icon/eye-off.png")}
                      alt=""
                    />
                  )}
                </div>
                {error === "password" ? (
                  <label id="warning">{errorMessage}</label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={
                !disabled
                  ? "Login_Button"
                  : "Login_Button login_button_disabled"
              }
            >
              <Spin spinning={loading}>
                {" "}
                <button disabled={disabled} 
                onClick={LoginHandler}
                >
                  Log In
                </button>
              </Spin>
            </div>
          </div>
          {/* <div className="Login_Forgot">
            <span onClick={forgotEventHandler}>Forgot Password ?</span>
          </div> */}
        </>
  );
};

export default Login_Details;
