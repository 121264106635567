import React, { useState } from 'react';
import VolumnChart from './VolumnChart';
import VolumnTable from './VolumnTable';

const Topic = ({ label, topics }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className='VolumnChart'>
      <VolumnChart
        name={label}
        data={topics}
        toggleFullScreen={toggleFullScreen}
        isFullScreen={isFullScreen}
      />
      {!isFullScreen && <VolumnTable name={label} data={topics} />}
    </div>
  );
};

export default Topic;
