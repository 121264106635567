// import React, { useState } from 'react';
// import '../../../css/sessionchart.css';

// const ITEMS_PER_PAGE = 20;

// const SessionTable = ({ sentiment, setIsSessionOpen, setSelectedSession }) => {
//   const [searchTopic, setSearchTopic] = useState('');
//   const [searchLanguage, setSearchLanguage] = useState('');
//   const [searchSentiment, setSearchSentiment] = useState('');
//   const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
//   const [sortColumn, setSortColumn] = useState('timestamp'); // 'timestamp', 'sentiments', or 'topics'
//   const [currentPage, setCurrentPage] = useState(1);

//   const handleViewClick = (session) => {
//     setSelectedSession(session);
//     setIsSessionOpen(true);
//   };

//   const handleSearchTopicChange = (event) => {
//     setSearchTopic(event.target.value);
//   };

//   const handleSearchLanguageChange = (event) => {
//     setSearchLanguage(event.target.value);
//   };

//   const handleSearchSentimentChange = (event) => {
//     setSearchSentiment(event.target.value);
//   };

//   const handleSort = (column) => {
//     setSortColumn(column);
//     setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
//   };

//   const sortedSentiment = [...sentiment].sort((a, b) => {
//     if (sortColumn === 'timestamp') {
//       const dateA = new Date(a.interaction[0].timestamp);
//       const dateB = new Date(b.interaction[0].timestamp);
//       return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
//     } else if (sortColumn === 'sentiments' || sortColumn === 'topics') {
//       const textA = a[sortColumn].toLowerCase();
//       const textB = b[sortColumn].toLowerCase();
//       if (textA < textB) return sortOrder === 'asc' ? -1 : 1;
//       if (textA > textB) return sortOrder === 'asc' ? 1 : -1;
//       return 0;
//     }
//     return 0;
//   });

//   const filteredSentiment = sortedSentiment.filter((row) => {
//     const topicMatch = row.topics.toLowerCase().includes(searchTopic.toLowerCase());
//     const languageMatch = row.language.toLowerCase().includes(searchLanguage.toLowerCase());
//     const sentimentMatch = row.sentiments.toLowerCase().includes(searchSentiment.toLowerCase());
//     return topicMatch && languageMatch && sentimentMatch;
//   });

//   const totalPages = Math.ceil(filteredSentiment.length / ITEMS_PER_PAGE);
//   const displayedData = filteredSentiment.slice(
//     (currentPage - 1) * ITEMS_PER_PAGE,
//     currentPage * ITEMS_PER_PAGE
//   );

//   const goToPreviousPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   return (
//     <>
//       <div className="search-bar">
//         <input
//           type="text"
//           placeholder="Search topics"
//           value={searchTopic}
//           onChange={handleSearchTopicChange}
//         />
//         <input
//           type="text"
//           placeholder="Search language"
//           value={searchLanguage}
//           onChange={handleSearchLanguageChange}
//         />
//         <input
//           type="text"
//           placeholder="Search sentiments"
//           value={searchSentiment}
//           onChange={handleSearchSentimentChange}
//         />
//         {/* <button className="export-button">Export</button> */}
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>No</th>
//             <th>Session id</th>
//             <th>User id</th>
//             <th onClick={() => handleSort('language')} style={{ cursor: 'pointer' }}>
//               Language {sortColumn === 'language' && (sortOrder === 'asc' ? '▲' : '▼')}
//             </th>
//             <th onClick={() => handleSort('topics')} style={{ cursor: 'pointer' }}>
//               Topic {sortColumn === 'topics' && (sortOrder === 'asc' ? '▲' : '▼')}
//             </th>
//             <th onClick={() => handleSort('sentiments')} style={{ cursor: 'pointer' }}>
//               Sentiment {sortColumn === 'sentiments' && (sortOrder === 'asc' ? '▲' : '▼')}
//             </th>
//             <th onClick={() => handleSort('timestamp')} style={{ cursor: 'pointer' }}>
//               Timestamp {sortColumn === 'timestamp' && (sortOrder === 'asc' ? '▲' : '▼')}
//             </th>
//             <th>View</th>
//           </tr>
//         </thead>
//         <tbody>
//           {displayedData.map((row, index) => (
//             <tr key={index}>
//               <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
//               <td>{row.session_id}</td>
//               <td>{row.userid}</td>
//               <td>{row.language}</td>
//               <td>{row.topics}</td>
//               <td
//                 className={
//                   row.sentiments === 'positive'
//                     ? 'successful'
//                     : row.sentiments === 'negative'
//                     ? 'failed'
//                     : 'neutral'
//                 }
//               >
//                 {row.sentiments}
//               </td>
//               <td>{row.interaction[0].timestamp}</td>
//               <td>
//                 <button className="view-button" onClick={() => handleViewClick(row)}>View</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="pagination">
//         <button onClick={goToPreviousPage} disabled={currentPage === 1}>
//           &laquo; Previous
//         </button>
//         <span>
//           Page {currentPage} of {totalPages}
//         </span>
//         <button onClick={goToNextPage} disabled={currentPage === totalPages}>
//           Next &raquo;
//         </button>
//       </div>
//     </>
//   );
// };

// export default SessionTable;

import React, { useState } from 'react';
import '../../../css/sessionchart.css';
import SessionChat from './SessionChat';

const ITEMS_PER_PAGE = 20;

const SessionTable = ({ sentiment }) => {
  const [searchTopic, setSearchTopic] = useState('');
  const [searchLanguage, setSearchLanguage] = useState('');
  const [searchSentiment, setSearchSentiment] = useState('');
  const [searchSessionID, setSearchSessionID] = useState('');
  const [sortOrder, setSortOrder] = useState('desc'); // Changed default to 'desc' for most recent
  const [sortColumn, setSortColumn] = useState('timestamp'); // Default to 'timestamp'
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSession, setSelectedSession] = useState(null); // New state for selected session
  const [isSessionOpen, setIsSessionOpen] = useState(false); // New state for session chat visibility

  const handleViewClick = (session) => {
    setSelectedSession(session);
    setIsSessionOpen(true);
  };

  const handleSearchTopicChange = (event) => {
    setSearchTopic(event.target.value);
  };

  const handleSearchLanguageChange = (event) => {
    setSearchLanguage(event.target.value);
  };

  const handleSearchSentimentChange = (event) => {
    setSearchSentiment(event.target.value);
  };

  const handleSearchSessionIDChange = (event) => {
    setSearchSessionID(event.target.value);
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const sortedSentiment = [...sentiment].sort((a, b) => {
    if (sortColumn === 'timestamp') {
      const dateA = new Date(a.interaction[0].timestamp);
      const dateB = new Date(b.interaction[0].timestamp);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    } else if (sortColumn === 'sentiments' || sortColumn === 'topics') {
      const textA = a[sortColumn].toLowerCase();
      const textB = b[sortColumn].toLowerCase();
      if (textA < textB) return sortOrder === 'asc' ? -1 : 1;
      if (textA > textB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const filteredSentiment = sortedSentiment.filter((row) => {
    const topicMatch = row.topics.toLowerCase().includes(searchTopic.toLowerCase());
    const languageMatch = row.language.toLowerCase().includes(searchLanguage.toLowerCase());
    const sentimentMatch = row.sentiments.toLowerCase().includes(searchSentiment.toLowerCase());
    const sessionMatch = row.session_id.toLowerCase().includes(searchSessionID.toLowerCase());
    return topicMatch && languageMatch && sentimentMatch && sessionMatch;
  });

  const totalPages = Math.ceil(filteredSentiment.length / ITEMS_PER_PAGE);
  const displayedData = filteredSentiment.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <>
      {isSessionOpen && (
        <SessionChat selectedSession={selectedSession} setIsSessionOpen={setIsSessionOpen} />
      )}
      <div className="search-bar">
      <input
          type="text"
          placeholder="Search sessionID"
          value={searchSessionID}
          onChange={handleSearchSessionIDChange}
        />
        <input
          type="text"
          placeholder="Search topics"
          value={searchTopic}
          onChange={handleSearchTopicChange}
        />
        <input
          type="text"
          placeholder="Search language"
          value={searchLanguage}
          onChange={handleSearchLanguageChange}
        />
        <input
          type="text"
          placeholder="Search sentiments"
          value={searchSentiment}
          onChange={handleSearchSentimentChange}
        />
        {/* <button className="export-button">Export</button> */}
      </div>
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Session id</th>
            <th>User id</th>
            <th onClick={() => handleSort('language')} style={{ cursor: 'pointer' }}>
              Language {sortColumn === 'language' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('topics')} style={{ cursor: 'pointer' }}>
              Topic {sortColumn === 'topics' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('sentiments')} style={{ cursor: 'pointer' }}>
              Sentiment {sortColumn === 'sentiments' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('timestamp')} style={{ cursor: 'pointer' }}>
              Timestamp {sortColumn === 'timestamp' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {displayedData.map((row, index) => (
            <tr key={index}>
              <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
              <td>{row.session_id}</td>
              <td>{row.userid}</td>
              <td>{row.language}</td>
              <td>{row.topics}</td>
              <td
                className={
                  row.sentiments === 'positive'
                    ? 'successful'
                    : row.sentiments === 'negative'
                    ? 'failed'
                    : 'neutral'
                }
              >
                {row.sentiments}
              </td>
              <td>{row.interaction[0].timestamp}</td>
              <td>
                <button className="view-button" onClick={() => handleViewClick(row)}>View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          &laquo; Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next &raquo;
        </button>
      </div>
    </>
  );
};

export default SessionTable;

