import React, { useState } from "react";
// import { IoMdClose } from "react-icons/io";
import "../../css/uploadjson.css"
import { Navigate, useNavigate } from "react-router-dom";
import {uploadJsonFile} from "../../api";
import { Spin } from "antd";

const Uploadjson = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [language, setLanguage] = useState("");
    const [typeOfContent, setTypeOfContent] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // const handleFileChange = (e) => {
    //     const selectedFile = e.target.files[0];
    //     const maxSize = 5 * 1024 * 1024; 

    //     if (selectedFile) {
    //         if (selectedFile.size > maxSize) {
    //             setError('**File size should not be greater than 5MB');
    //             setFile(null);
    //         } else if (selectedFile.type !== 'application/json') {
    //             setError('**File type should be JSON');
    //             setFile(null);
    //         } else {
    //             setError('');
    //             setFile(selectedFile);
    //         }
    //     }
    // };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; 
    
        if (selectedFile) {
            const fileName = selectedFile.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
    
            if (selectedFile.size > maxSize) {
                setError('**File size should not be greater than 5MB');
                setFile(null);
            } else if (fileExtension !== 'json' && fileExtension !== 'jsonl') {
                setError('**File type should be JSON or JSONL');
                setFile(null);
            } else {
                setError('');
                setFile(selectedFile);
            }
        }
    };

    const handleLanguageChange = (e) => {
        console.log(e.target.value);
        setLanguage(e.target.value);
    };

    const handleTypeChange = (e) => {
        console.log(e.target.value);
        setTypeOfContent(e.target.value);
    };

    const handleSubmitButton = async () => {
        setLoading(true);
        const email = JSON.parse(localStorage.getItem('email'));
        const id = JSON.parse(localStorage.getItem('id'));

        if (!file || !language || !typeOfContent) {
            alert("Please fill out all fields.");
            setLoading(false);
            return;
        }
        try {
            const result = await uploadJsonFile(email, file, language, typeOfContent);
            console.log(result);
            setLoading(false);
            // navigate('/dataProcessing')
            navigate(`/${id}/dashboard`);
        } catch (err) {
            console.error("Error uploading file:", err);
        }
    };
 
 return (
    <section className="modal">
    <article className="modal-content p-lg-4">
    <main className="modal-mainContents">
        <h5 className="modal-title">Upload File</h5>
        <hr />
        <div className="file-upload mt-lg-3">
            <label htmlFor="fileInput" className="form-label">Choose a file</label>
            <span style={{ marginLeft: '45px' }}>:</span>
            <input type="file" className="form-select" id="fileInput" onChange={handleFileChange}/>
            {error ? (
                <p style={{ color: 'red', fontSize: '14px'  }}>{error}</p>
            ) : (
                file && ""
            )}
        </div>
        <div className="dropdowns mt-lg-3">
            <label htmlFor="dropdown1" className="form-label">Language</label>
            <span style={{ marginLeft: '65px' }}>:</span>
            <select className="form-select" id="dropdown1"  onChange={handleLanguageChange}>
                <option value="">Select an option</option>
                <option value="Multi-Language">Multi-Language</option>
                <option value="Single-Language">Single-Language</option>
            </select>
        </div>
        <div className="dropdowns mt-lg-3">
            <label htmlFor="dropdown2" className="form-label">Format of content </label>
            <span style={{ marginLeft: '10px' }}>:</span>
            <select className="form-select" id="dropdown2" onChange={handleTypeChange}>
                <option value="">Select an option</option>
                <option value="Query">Query</option>
                <option value="Session">Session</option>
            </select>
        </div>
        <div className="modal-button text-end">
        <div
              className={
                !disabled
                  ? "Login_Button"
                  : "Login_Button login_button_disabled"
              }
            >
              <Spin spinning={loading}>
                {" "}
                <button disabled={disabled} 
                onClick={handleSubmitButton}
                >
                  Submit
                </button>
              </Spin>
            </div>
        </div>
    </main>
    </article>
    </section>
 );
};

export default Uploadjson;
