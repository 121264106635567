import React, { useState } from 'react';
import '../../css/header.css'; // Ensure you have the CSS file for styling

const Header = ({ id,setId }) => {
    const [selectedId, setSelectedId] = useState();

    const handleClick = (id) => {
        // setSelectedId(id);
        setId(id);
    };

    return (
        <div className="Header">
            <h2
                className={`Header-item ${id === 1 ? 'selected' : ''}`}
                onClick={() => handleClick(1)}
            >
                Language
            </h2>
            <h2
                className={`Header-item ${id === 2 ? 'selected' : ''}`}
                onClick={() => handleClick(2)}
            >
                Topic
            </h2>
            <h2
                className={`Header-item ${id === 3 ? 'selected' : ''}`}
                onClick={() => handleClick(3)}
            >
                Session
            </h2>
        </div>
    );
};

export default Header;
