import axios from 'axios';

const API_URL = 'https://vireinsightsapi.azurewebsites.net/';
// const API_URL = 'http://127.0.0.1:8000/';


const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  };

export const login = async (loginData) => {
    try {
        const response = await axios.post(`${API_URL}login`, loginData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async (email) => {
    try {
        const response = await axios.post(`${API_URL}logout`, { email: email });
        return response;
    } catch (error) {
        throw error;
    }
};

export const uploadJsonFile = async (email, file, language, type_of_content) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('file', file);
    formData.append('language', language);
    formData.append('type_of_content', type_of_content);

    try {
        const response = await axios.post(`${API_URL}upload_json`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkJsonFile = async (email) => {
    try {
        const response = await axios.get(`${API_URL}check_json_file`, {
            params: { email }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDetectedLanguage = async (email) => {
    try {
        const response = await axios.get(`${API_URL}Dashboard/Language`, {
            params: { email }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDetectedTopic = async (email) => {
    try {
        const response = await axios.get(`${API_URL}Dashboard/Topics`, {
            params: { email }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDetectedSession = async (email) => {
    try {
        const response = await axios.get(`${API_URL}Dashboard/Sessions`, {
            params: { email }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

