import React from "react";
import { Spin, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import "../css/dataprocessing.css"
import {logout} from "../api";


const DataProcessing = () => {
  const navigate = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const logoutUser = async () => {
    const email = JSON.parse(localStorage.getItem('email'));
    try {
            console.log("email : ",email);
            const res = await logout(email);
            navigate('/')

    } catch (err) {
            console.error("session error :", err);
    }
}

  return (
    <div className="processing-container">
      <div className="processing-content">
        <Spin indicator={antIcon} />
        <h2>Your data is being processed</h2>
        <p>We will send an email once the data is processed.</p>
        <button className="logout-button" onClick={logoutUser}>Logout</button>
      </div>
    </div>
  );
};

export default DataProcessing;
