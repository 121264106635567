import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import '../../css/volumnchart.css';

const ITEMS_PER_PAGE = 10;

const VolumnChart = ({ name, data }) => {
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedArray = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setSortedData(sortedArray);
    setCurrentPage(1); // Reset to the first page when sorting
  };

  const totalPages = Math.ceil(sortedData.length / ITEMS_PER_PAGE);
  const displayedData = sortedData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage-1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div className="VolumnChart">
      <table className="data-table" style={{ height: '400px' }}>
        <thead>
          <tr>
            <th>No</th>
            <th onClick={() => sortData('topic')} style={{ cursor: 'pointer' }}>{name}</th>
            <th onClick={() => sortData('count')} style={{ cursor: 'pointer' }}>Volume</th>
            <th onClick={() => sortData('percentage_negative')} style={{ cursor: 'pointer' }}>%negative sessions</th>
          </tr>
        </thead>
        <tbody>
          {displayedData.map((item, index) => (
            <tr key={index}>
              <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
              <td>{item.topic}</td>
              <td>{item.count}</td>
              <td>{item.percentage_negative.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          &laquo; Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next &raquo;
        </button>
      </div>
    </div>
  );
};

export default VolumnChart;

