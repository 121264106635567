import React from 'react';
import { Pie, Line } from 'react-chartjs-2';
import '../../../css/sessionchart.css';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const processData = (data) => {
  const sentimentCounts = { neutral: 0, negative: 0, positive: 0 };
  const lineChartData = { labels: [], neutral: [], negative: [], positive: [] };

  // data.forEach(session => {
  //   let date_index;
  //   // session.interaction.forEach(interact => {
  //   //   const date = interact.timestamp.split(' ')[0]; // Extract date only
  //     // if (!lineChartData.labels.includes(date)) {
  //     //   lineChartData.labels.push(date);
  //     // }
  //     // date_index = lineChartData.labels.indexOf(date);
  //   // });
  //   const date = session.interaction[0].timestamp.split(' ')[0];

  //   if (!lineChartData.labels.includes(date)) {
  //     lineChartData.labels.push(date);
  //   }
  //   date_index = lineChartData.labels.indexOf(date);

  //   sentimentCounts[session.sentiments]++;
  //   if (session.sentiments === 'neutral') {
  //     lineChartData.neutral[date_index]++;
  //   } else if (session.sentiments === 'negative') {
  //     lineChartData.negative[date_index]++;
  //   } else {
  //     lineChartData.positive[date_index]++;
  //   }
  // });

  // return { sentimentCounts, lineChartData };
  data.forEach(session => {
    let date_index;
    const date = session.interaction[0].timestamp.split(' ')[0]; // Extract date from the first interaction only
  
    if (!lineChartData.labels.includes(date)) {
      lineChartData.labels.push(date);
    }
    date_index = lineChartData.labels.indexOf(date);
  
    // Initialize counts if they don't exist
    if (!lineChartData.neutral[date_index]) {
      lineChartData.neutral[date_index] = 0;
    }
    if (!lineChartData.negative[date_index]) {
      lineChartData.negative[date_index] = 0;
    }
    if (!lineChartData.positive[date_index]) {
      lineChartData.positive[date_index] = 0;
    }
  
    // Update counts based on sentiments
    if (session.sentiments === 'neutral') {
      lineChartData.neutral[date_index]++;
    } else if (session.sentiments === 'negative') {
      lineChartData.negative[date_index]++;
    } else {
      lineChartData.positive[date_index]++;
    }
  
    // Update sentiment counts
    sentimentCounts[session.sentiments]++;
  });
  
  return { sentimentCounts, lineChartData };
};

const SessionStatusChart = ({ sentiment }) => {
  const { sentimentCounts, lineChartData } = processData(sentiment);
  
  let total_sentiment = sentimentCounts.neutral + sentimentCounts.negative + sentimentCounts.positive;

// Calculate percentages
let neutralPercentage = ((sentimentCounts.neutral / total_sentiment) * 100).toFixed(2);
let negativePercentage = ((sentimentCounts.negative / total_sentiment) * 100).toFixed(2);
let positivePercentage = ((sentimentCounts.positive / total_sentiment) * 100).toFixed(2);

// Prepare data for Pie chart
const pieData = {
  labels: [
    `Neutral (${neutralPercentage}%)`,
    `Negative (${negativePercentage}%)`,
    `Positive (${positivePercentage}%)`
  ],
  datasets: [
    {
      label: 'Sentiments',
      data: [sentimentCounts.neutral, sentimentCounts.negative, sentimentCounts.positive],
      backgroundColor: ['#007bff', 'red', 'green'],
    },
  ],
};

  // Prepare data for Line chart
  const lineData = {
    labels: lineChartData.labels,
    datasets: [
      {
        label: 'Neutral',
        data: lineChartData.neutral,
        borderColor: '#007bff',
        backgroundColor: 'transparent',
        pointBackgroundColor: '#007bff',
        pointBorderColor: '#007bff',
      },
      {
        label: 'Negative',
        data: lineChartData.negative,
        borderColor: 'red',
        backgroundColor: 'transparent',
        pointBackgroundColor: 'red',
        pointBorderColor: 'red',
      },
      {
        label: 'Positive',
        data: lineChartData.positive,
        borderColor: 'green',
        backgroundColor: 'transparent',
        pointBackgroundColor: 'green',
        pointBorderColor: 'green',
      },
    ],
  };

  // Options for Line chart
  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
      },
      title: {
        display: true,
        text: 'Session Status Trends',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className='session-chart-container' style={{height: '350px'}}>
      <div className='pie-chart' style={{ height: '100%'}}>
        <Pie data={pieData} />
      </div>
      <div className='line-chart' style={{height: '100%'}}>
        <Line data={lineData} options={lineOptions} />
      </div>
    </div>
  );
};

export default SessionStatusChart;
