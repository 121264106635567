import React from "react";
// import Forgot_Password from "../Components/Login/Forgot_Password";
import Login_Details from "../components/Login/Login_Details";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
// import Reset_Password from "../Components/Login/Reset_Password";

const Login = ({ setLogin }) => {
  const [email, setEmail] = useState("");

  return (
    <div className="Login">
      <div
        className="Login_Card"
        style={{
          marginTop: "30px",
        }}
      >
        <Login_Details
          email={email}
          setEmail={setEmail}
          setLogin={setLogin}
        />
      </div>
    </div>
  );
};

export default Login;
