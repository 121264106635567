import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import '../../css/volumnchart.css';

const VolumnChart = ({ name, data, toggleFullScreen, isFullScreen }) => {
  const [showTop10, setShowTop10] = useState(true);

  const sortedData = [...data].sort((a, b) => b.count - a.count);
  const displayedData = showTop10 ? sortedData.slice(0, 10) : sortedData;

  const topics = displayedData.map((item) => item.topic);
  const counts = displayedData.map((item) => item.count);

  const chartData = {
    labels: topics,
    datasets: [
      {
        label: 'Overall ' + name + ' volume',
        data: counts,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: Math.max(...counts) + 10,
      },
    },
  };

  const handleChartClick = () => {
    if (!isFullScreen) {
      toggleFullScreen();
    }
    setShowTop10(!showTop10);
  };

  return (
    <div className={`VolumnChart ${isFullScreen ? 'fullscreen' : ''}`}>
      <div
        className="Barchart"
        onClick={handleChartClick}
        style={{ height: isFullScreen ? '100vh' : '400px', width: isFullScreen ? '100vw' : 'auto'}}
      >
        {isFullScreen && (
          <button className="close-button" onClick={toggleFullScreen}>
            &times;
          </button>
        )}
        {!isFullScreen && (
          <p style={{fontSize:'10px', position: 'absolute', bottom: '5px' }}>
            Click card to see the entire distribution
          </p>
        )}
        <Bar data={chartData} options={options} style={{ height: '100%', paddingBottom:'20px' }} />
      </div>
    </div>
  );
};

export default VolumnChart;
