import React , {useState,useEffect} from 'react'
import Header from '../components/Dashboard/Header'
import Language from '../components/Dashboard/Language'
import Topic from '../components/Dashboard/Topic'
import Session from '../components/Dashboard/Session/Session'
import '../css/dashboard.css'
import { useNavigate } from "react-router-dom";
import {getDetectedLanguage, logout,getDetectedTopic,getDetectedSession} from "../api";
const Dashboard = () => {
    const [isSessionOpen, setIsSessionOpen] = useState(false)
    const [selectedSession, setSelectedSession] = useState(null);
    const [topics,setTopics] = useState([]);
    const [languages,setLanguages] = useState([]);
    const [sentiment,setSentiment] = useState([]);
    const [id,setId] = useState(1);

    const navigate = useNavigate();

    const getTopics = async () => {
        const email = JSON.parse(localStorage.getItem('email'));
        try {
                const topic_result = await getDetectedTopic(email);
                console.log("topic_result : ",topic_result);
                const filteredData = topic_result.filter(
                        (item) => item.topic.toLowerCase() !== 'na' && item.topic.toLowerCase() !== 'unidentified'
                      );
                setTopics(filteredData);

        } catch (err) {
                console.error("topic error :", err);
        }
    }

    const getLanguages = async () => {
        const email = JSON.parse(localStorage.getItem('email'));
        try {
                const language_result = await getDetectedLanguage(email);
                console.log("language_result : ",language_result);
                const filteredData = language_result.filter(
                        (item) => item.topic.toLowerCase() !== 'na' && item.topic.toLowerCase() !== 'unidentified'
                      );
                setLanguages(filteredData);

        } catch (err) {
                console.error("language error :", err);
        }
    }

    const getSessions = async () => {
        const email = JSON.parse(localStorage.getItem('email'));
        try {
                const session_result = await getDetectedSession(email);
                console.log("session_result : ",session_result);
                setSentiment(session_result);

        } catch (err) {
                console.error("session error :", err);
        }
    }
    
    const logoutUser = async () => {
        const email = JSON.parse(localStorage.getItem('email'));
        try {
                console.log("email : ",email);
                const res = await logout(email);
                navigate('/')

        } catch (err) {
                console.error("session error :", err);
        }
    }
    

    useEffect(() => {
        getLanguages();
        getTopics();
        getSessions();
    }, []);
        

    return <div className='Dashboard-screen'>
            <div className="Dashboard">
                    <div className='header'>
                        <Header id={id} setId={setId}/>
                        <button className='logout' onClick={logoutUser}>Logout</button>
                    </div>
                    
                    {id === 1 ? (<div className="content"><Language label="Language" language={languages} /></div>) : id === 2 ? (<div className="content"><Topic label="Topic" topics={topics}/></div>) : (<div className="content" ><Session sentiment={sentiment} setIsSessionOpen={setIsSessionOpen} setSelectedSession={setSelectedSession} /></div>)}
            </div>
         </div> 
    
}

export default Dashboard