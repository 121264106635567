import React ,{useState}from 'react'
import VolumnChart from './VolumnChart'
import VolumnTable from './VolumnTable';


const Language = ({label,language}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className='VolumnChart'>
      <VolumnChart
        name={label}
        data={language}
        toggleFullScreen={toggleFullScreen}
        isFullScreen={isFullScreen}
      />
      {!isFullScreen && <VolumnTable name={label} data={language} />}
    </div>
  );
}

export default Language