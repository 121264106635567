import React from 'react';
import '../../../css/sessionchart.css';

const SessionChat = ({ selectedSession, setIsSessionOpen }) => {
  const handleCloseClick = () => {
    setIsSessionOpen(false);
  };
  const toggleFullScreen = () => {
    setIsSessionOpen(false);
  };

  const messages = selectedSession.interaction.map((interact, index) => [
    {
      id: index * 2 + 1,
      timestamp: interact.timestamp,
      message: interact.query,
      feedback: false,
      isSender: true,
    },
    {
      id: index * 2 + 2,
      timestamp: interact.timestamp,
      message: interact.response,
      feedback: true,
      isSender: false,
    },
  ]).flat();

  return (
    <section className="modal" style={{backdropFilter: 'blur(5px)',backgroundColor: 'rgba(0, 0, 0, 0.25)'}}>
      <article className="modal-content p-lg-4">
        <main className="modal-mainContents">
          <div className="detail-view">
            <div className="detail-header">
              <span>Session id: {selectedSession.session_id}</span>
              <span className={selectedSession.sentiments === 'positive' ? 'successful' : selectedSession.sentiments === 'negative' ? 'failed' : 'neutral'}>
                {selectedSession.sentiments}
              </span>
              <button className="close-button" onClick={handleCloseClick}>
                &times;
              </button>
            </div>
            <div className="detail-content">
              <div className="detail-content-row">
                <div>
                  <h3>Topic</h3>
                  <p>{selectedSession.topics}</p>
                </div>
                <div>
                  <h3>Language</h3>
                  <p>{selectedSession.language}</p>
                </div>
              </div>
              <div className="detail-content-row">
                <div>
                  <h3>User id</h3>
                  <p>{selectedSession.userid}</p>
                </div>
                <div>
                  <h3>Date</h3>
                  <p>{selectedSession.interaction[0].timestamp}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-container">
            {messages.map((message, index) => (
              <div className={`chat-bubble ${message.isSender ? 'sender' : 'recipient'}`} key={index}>
                <div className="message-info">
                  <span className="timestamp">{message.timestamp}</span>
                </div>
                <div className="message">{message.message}</div>
              </div>
            ))}
          </div>
        </main>
      </article>
    </section>
  );
};

export default SessionChat;
