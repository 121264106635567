import React from 'react'
import SessionStatusChart from './SessionChart'
import SessionTable from './SessionTable'
import '../../../css/sessionchart.css'


const Session = ({sentiment,setIsSessionOpen,setSelectedSession}) => {
  return (
    <div className='Session'>
      <div className='Session-chart-bar'>
        <SessionStatusChart sentiment={sentiment} />
      </div>
      <div className='Session-table-bar'>
        <SessionTable sentiment={sentiment} setIsSessionOpen={setIsSessionOpen} setSelectedSession={setSelectedSession}/>
      </div>
    </div>
  )
}

export default Session