import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import Login from "./containers/Login";
import Login_Details from "./components/Login/Login_Details";
import './css/index.css';
import './App.css';
import Uploadjson from './components/Uplaod/Uploadjson';
import DataProcessing from './containers/DataProcessing';

function App() {
  // const key = 'demo-analysis'
  return (
    <div className="App">
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/:userId/upload" element={<Uploadjson />} />
              <Route path="/dataProcessing" element={<DataProcessing />}/>
              <Route path="/:userId/dashboard" element={<Dashboard />} />
            </Routes>
          </Router>
    </div>
  );
}

export default App;
